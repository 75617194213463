import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import styles from "./home.module.scss"
import aboutGameImg from "../../assets/images/aboutGame.png"
import rummyImg from "../../assets/images/rummy.png"
import inactiveRummy from "../../assets/images/inactiverummy.png"
import ludoImg from "../../assets/images/ludo.png"
import activeLudo from "../../assets/images/activeludo.png"
import OurMission from '../ourMission/OurMission';
import Experience from '../experience/Experience';
import AboutUs from '../aboutUs/AboutUs';
import Products from '../products/Products';
import WorkWithUs from '../workWithUs/WorkWithUs';
import Footer from '../footer/Footer';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import banner11 from "../../assets/images/banner11.png"
import banner12 from "../../assets/images/banner12.png"
import banner13 from "../../assets/images/banner13.png"
import Navbar from '../navbar/Navbar';
import upbutton from "../../assets/images/up.png"
import ludo from "../../assets/images/ludobackground.png"

const Home = () => {
    const [hide, setHide] = useState(true)
    const [showHide, setShowHide] = useState(false)
    const [width, setWidth] = useState(0);
    const [scrollHeight, setScrollHeight] = useState(0)
    console.log("scrollHeight", scrollHeight)
    const [animate, setAnimate] = useState(false)
    useLayoutEffect(() => {
        function updateSize() {
            setWidth(window.innerWidth)
        }
        updateSize();
        window.addEventListener('resize', updateSize);
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    const height = "100%"

    const items: any = [
        <img src={banner11} alt="banner1" width={width} height={height} />,
        <img src={banner12} alt="banner2" width={width} height={height} />,
        <img src={banner13} alt="banner3" width={width} height={height} />
    ]

    const refToTop = useRef<HTMLInputElement>(null);

    const onScroll = (event: any) => {
        if (event.target?.scrollingElement?.scrollTop) {
            setScrollHeight(event.target.scrollingElement.scrollTop)
        } else {
            setScrollHeight(event.target.scrollTop)
        }
    }

    const scrollToTop = () => {
        refToTop.current && refToTop.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }

    useEffect(() => {
        window.addEventListener("scroll", onScroll, { capture: true });
        return () => window.removeEventListener("scroll", onScroll, { capture: true });
    }, []);

    const handleClick = () => {
        setHide(true)
        setShowHide(false)
    }

    const handleClick1 = () => {
        setShowHide(true)
        setHide(false)
    }


    return (
        <>

            <section className={styles.home_cont} ref={refToTop}>
                <Navbar scrollHeight={scrollHeight} />
                <div className={styles.banner_cont}>
                    <AliceCarousel autoPlay autoPlayInterval={3000} infinite
                        disableDotsControls={false} disableButtonsControls={true} mouseTracking items={items} />
                </div>
            </section>
            <>

                <div className={styles.main_cont}>
                    {hide &&
                        <section className={`${styles.aboutGame_cont} ${hide ? styles.aboutGame_cont2 : ""}`}>
                            <div className={styles.abGame_subCont}>
                                <div className={styles.vyka_rummyImgCont}>
                                    <img className={styles.vykaRummy_img} src={aboutGameImg} alt='aboutGameImg' />
                                </div>
                                <div className={styles.aboutgame_text}>
                                    <span>Vyka Rummy</span> is a rummy website that prioritizes customer satisfaction and reliability. We thrive for a positive user experience, likely with features such as a user-friendly interface, secure transactions, and responsive customer support. We also emphasize fair play and responsible gaming practices
                                </div>
                            </div>

                        </section>
                    }

                    {
                        showHide &&
                        <section
                            className={`${styles.aboutGame_cont}
                         ${showHide ? styles.aboutGame_cont1 : ""}`}>
                            <div className={styles.abGame_subCont}>
                                <div className={styles.vyka_rummyImgCont}>
                                    <img className={styles.vykaRummy_img1} src={ludo} alt="ludo" />
                                </div>

                                <div className={styles.aboutgame_text}>
                                    <span>Vyka Ludo</span>, launching soon, is poised to join Vyka Rummy in its commitment to exceptional customer experiences. <span>Vyka Ludo</span> will prioritize both player satisfaction and platform reliability, ensuring a secure and enjoyable environment for Ludo enthusiasts. Stay tuned for more details and prepare for exciting Ludo gameplay!
                                </div>
                            </div>
                        </section>
                    }

                </div>
                <div className={styles.abtn_cont}>
                    {
                        hide ? <div className={styles.rummyImg_cont} onClick={handleClick}>
                            <img className={styles.rummyImg} src={rummyImg} alt='rummyImg' />
                        </div> : <div className={styles.rummyImg_cont} onClick={handleClick}>
                            <img className={styles.inactiveRummyImg} src={inactiveRummy} alt='inactiveRummy' />
                        </div>
                    }

                    {
                        showHide ?

                            <div className={styles.ludoImg_cont} onClick={handleClick1}>
                                <img className={styles.activeLudo} src={activeLudo} alt='activeLudo' />
                            </div> :
                            <div className={styles.ludoImg_cont} onClick={handleClick1}>
                                <img className={styles.ludoImg} src={ludoImg} alt='ludoImg' />
                            </div>
                    }
                </div>
            </>


            <OurMission />
            <Experience />
            <AboutUs />
            <Products />
            <WorkWithUs />

            <div className={scrollHeight > 55 ? `up_btncont` : `up_btncontnone`} onClick={scrollToTop}>
                <img className={styles.up_btn} src={upbutton} alt='upbutton' />
            </div>
        </>
    );
}

export default Home;
