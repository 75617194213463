import React, { useEffect } from 'react';
import styles from "../winmoneygameapp/wmga.module.scss"

const HowToPlayPointsRummyOnVykaRummy = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
  return (
    <div className={styles.thirRummy_cont}>
      <h3>How to Play Points Rummy on Vyka Rummy</h3>

      <div className={styles.content}>
      <p>Points Rummy is a dynamic and popular variation of Indian rummy, ideal for groups of 2 to 6 players. Here's a comprehensive guide on how to play Points Rummy on Vyka Rummy</p>
      </div>

      <div className={styles.content}>
      <h3>Game Setup</h3>
      <ul>
        <li>Points Rummy is played with two decks of 52 cards each, including jokers.</li>
        <li>Each player is dealt 13 cards to begin the game.</li>
      </ul>
      </div>

      <div className={styles.content}>
      <h3>Stock Pile</h3>
      <ul>
        <li>Remaining cards are placed face down in the center as a closed pile.</li>
      </ul>
      </div>

      <div className={styles.content}>
      <h3>Open Pile</h3>
      <ul>
        <li>The top card from the stock pile is turned face up next to it to start the open pile.</li>
      </ul>
      </div>

      <div className={styles.content}>
        <h3>Gameplay</h3>
      </div>

      <div className={styles.content}>
        <h3>Follow these steps to play Points Rummy:</h3>
      </div>

      <div  className={styles.content}>
        <h3>Step 1: Deal the Cards</h3>
        <ul>
            <li>The dealer shuffles and distributes 13 cards to each player.</li>
            <li>Remaining cards form the closed pile, and one card is flipped to start the open pile.</li>
        </ul>
      </div>

      <div  className={styles.content}>
        <h3>Step 2: Gameplay</h3>
        <ul>
            <li>Players take turns drawing a card from the open or closed pile and then discarding a card to the open pile.</li>
            <li> Jokers can be picked up from either pile and used to replace any card in the player's hand.</li>
        </ul>
      </div>

      <div  className={styles.content}>
        <h3>Step 3: Making a Declaration</h3>
        <ul>
            <li>To declare, a player must have at least two sequences, including a mandatory pure sequence.</li>
            <li>A pure sequence consists of consecutive cards of the same suit.</li>
            <li>The second sequence can be pure, impure (using jokers), or a combination of both.</li>
        </ul>
      </div>

      <div  className={styles.content}>
        <h3>Step 4: Scoring</h3>
        <ul>
            <li>The winner is the first player to make a valid declaration with 0 points.</li>
            <li> Unsuccessful declarations result in penalty points based on unmatched cards.</li>
        </ul>
      </div>

      <div  className={styles.content}>
        <h3>Points Rummy Scoring Explained</h3>
        <p>Points Rummy involves scoring based on card values and declarations. Here's how the scoring system works:</p>
        <ul>
            <li>Face cards (J, Q, K, A): 10 points each</li>
            <li>Numbered cards: Face value</li>
            <li> Jokers: 0 points</li>
        </ul>
      </div>

      <div  className={styles.content}>
        <h3>Formula for Calculation</h3>
        <ul>
            <li>The loser's score is the sum of points from ungrouped cards.</li>
        </ul>
      </div>

      <div  className={styles.content}>
        <p>Tips to Score Well in Points</p>
      </div>

      <div  className={styles.content}>
        <p>Improve your game with these strategic tips:</p>
        <ul>
            <li>Prioritize forming a pure sequence early.</li>
            <li>Organize cards into sets and sequences quickly.</li>
            <li>Avoid picking up irrelevant cards.</li>
            <li>Keep track of opponents' discards to anticipate their moves.</li>
        </ul>
      </div>

      <div className={styles.content}>
      <h3>Points Rummy Winning Calculation</h3>
      <p>Points Rummy is a game of skill where the first player to declare with 0 points wins. Let's explore how winning is calculated:</p>
      </div>

      <div className={styles.content}>
      <h3>Example:</h3>
      <p>Player A declares with 0 points, while Players B, C, and D have ungrouped cards.</p>
      <ul>
        <li>Player B: 17 points</li>
        <li>Player C: 64 points</li>
        <li>Player D: 78 points</li>
      </ul>
      </div>

      <div className={styles.content}>
      <h3>Detailed Calculation:</h3>
      <p>Player A declares with 0 points, while Players B, C, and D have ungrouped cards.</p>
      <ol>
        <li>Total points of losing players: 17 + 64 + 78 = 159 points</li>
        <li>Multiply total points by the point value (₹1 per point).</li>
      </ol>
      </div>


      <div className={styles.content}>
      <p>In the example:</p>
      <p>Total winnings: 159 points  ₹1 per point = ₹159</p>
      <p>Player A wins ₹159 in this game.</p>
      <p>Note: The point value per point can vary based on game rules agreed upon before play.</p>
      <p>Play Points Rummy with skill and strategy on Vyka Rummy, mastering the scoring system and earning your way to victory</p>
      </div>
      
    </div>
  );
}

export default HowToPlayPointsRummyOnVykaRummy;
