import React, { useEffect } from 'react';
import styles from "./thirteenCardsRummy.module.scss"

const ThirteenCardsRummy = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className={styles.thirRummy_cont}>
      <h3>13 Card Rummy: A Popular Indian Card Game</h3>

      <div className={styles.content}>
      <h3>Introduction </h3>
      <p>13 Card Rummy is a variant of traditional Indian Rummy played with 13 cards. It's a game of skill and strategy, often enjoyed at social gatherings and online platforms like Vyka Rummy. </p>
      </div>

      <div className={styles.content}>
      <h3>Why 13 Card Rummy is Popular in India </h3>
      <ul>
        <li>Easy to learn yet challenging to master.</li>
        <li>Suitable for all ages and skill levels.</li>
        <li> Social game bringing families and friends together.</li>
        <li>Prominent Bollywood endorsements.</li>
      </ul>
      </div>

      <div className={styles.content}>
      <h3>Objective of 13 Card Rummy </h3>
      <p>The goal is to arrange 13 cards into valid sequences (pure sequence required) and sets to declare a winning hand. </p>
      </div>

      <div className={styles.content}>
      <h3>Sets and Sequences </h3>
      <ul>
        <li><span>Sequence:</span>Three or more consecutive cards of the same suit (e.g., 4♤, 5♤, 6♤).</li>
        <li><span>Set:</span> Three or four cards of the same rank (e.g., 7♧️, 7♦️, 7♥️).</li>
      </ul>
      </div>

      <div className={styles.content}>
      <h3>Valid Declaration </h3>
      <p>Requires at least two sequences (including one pure sequence) and additional sequences or sets.</p>
      </div>

      <div className={styles.content}>
      <h3>Gameplay & Rules</h3>
      <ol>
        <li>
          <h4>Cards Distribution:</h4>
          <ol>
            <li>Players are dealt 13 cards each. </li>
            <li>Remaining cards form the stock and open pile.</li>
          </ol>
        </li>
        <li>
          <h4>Sorting</h4>
        <ol>
            <li>Arrange cards into sequences and sets. </li>
          </ol>
        </li>
        <li>
          <h4>Drawing and Discarding:</h4>
        <ol>
            <li>Draw from stock or open pile. </li>
            <li>Discard to maintain a valid hand.</li>
          </ol>
        </li>
        <li>
          <h4>Declaration:</h4>
        <ol>
            <li>Discard one card to declare.</li>
            <li>Must have valid sequences and sets.</li>
          </ol>
        </li>
      </ol>
      </div>

      <div className={styles.content}>
      <h3>Terms Used in 13 Card Rummy </h3>
      <ul>
        <li>Sequence: Consecutive cards of the same suit. </li>
        <li>Pure Sequence: Sequence without jokers. </li>
        <li>Set: Cards of the same rank. </li>
        <li>Wild Card: Joker, usable to complete sequences/sets. </li>
        <li>Stock: Face-down pile. </li>
        <li>Open Pile: Face-up pile. </li>
      </ul>
      </div>

      <div className={styles.content}>
      <h3>How Points are Calculated </h3>
      <ul>
        <li>Points assigned based on unmatched cards. </li>
        <li>Jokers have zero points.</li>
      </ul>
      </div>

      <div className={styles.content}>
      <h3>Variants in 13 Card Rummy </h3>
      <ul>
        <li>Points Rummy: Focus on scoring the fewest points. </li>
        <li>Deal Rummy: Meld all cards into sets or sequences. </li>
      </ul>
      </div>

      <div className={styles.content}>
      <h3>Strategies to Win at 13 Card Rummy </h3>
      <ol>
        <li><span>Balance Your Hand:</span> Mix high and low-value cards. </li>
        <li><span>Pay Attention to Discards:</span>  Prevent opponents from getting needed cards.</li>
        <li><span>Meld Quickly:</span> Form sets and sequences fast. </li>
        <li><span>Keep Your Hand Low:</span> Minimize points if unable to declare. </li>
        <li><span>Understand Scoring Rules:</span> Make informed melding decisions. </li>
        <li><span>Regular Practice:</span> Enhance strategic thinking. </li>
        <li><span>Bluffing:</span> Mislead opponents about your hand. </li>
        <li><span>Monitor Opponents:</span> Block their melding opportunities. </li>
      </ol>
      </div>

      <div className={styles.content}>
      <h3>Conclusion </h3>
      <p>13 Card Rummy is a captivating card game requiring skill, strategy, and a bit of luck. Its popularity in India stems from its social nature and accessibility across generations. Whether played offline or online, the game continues to engage players with its dynamic gameplay.</p>
      </div>
    </div>
  );
}

export default ThirteenCardsRummy;
