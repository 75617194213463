import React, { useEffect } from 'react';
import styles from "../thirteencardsrummy/thirteenCardsRummy.module.scss"

const PractiseGames = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
  return (
    <div className={styles.thirRummy_cont}>
      <h3>Free Rummy Games Online: Practice Games on Vyka Rummy</h3>

      <div className={styles.content}>
      <h3>Introduction </h3>
      <p>Sharpen your rummy skills and enjoy the excitement of the game without any financial risk by playing free Rummy games on Vyka Rummy.</p>
      </div>

      <div className={styles.content}>
      <h3>Why Play Free Rummy on Vyka Rummy</h3>
      <ul>
        <li>Learn and improve your rummy strategies.</li>
        <li>Test your skills against other players.</li>
        <li>Access a variety of popular rummy variants.</li>
      </ul>
      </div>

      <div className={styles.content}>
      <h3>Variants of Free Rummy Games on Vyka Rummy App</h3>
      </div>

      <div className={styles.content}>
      <h3>Pool Rummy</h3>
      <p>Pool rummy is a thrilling variant where players aim to keep their points below a specific limit to win.</p>
      <ul>
        <li>Free Chips: Use up to 5000 free chips to play pool rummy.</li>
        <li>Number of Players: Tables can accommodate 2 to 6 players. </li>
        <li>Variants: Includes Points Pool Rummy, Deals Pool Rummy, Time Pool Rummy.</li>
      </ul>
      </div>

      <div className={styles.content}>
      <h3>Points Rummy </h3>
      <p>Players must declare their points before melding cards, with the player having the lowest points winning the game.</p>
      <ul>
        <li>Free Chips: Enjoy up to 5000 free chips to play points rummy.</li>
        <li>Number of Players: Tables can accommodate 2 to 6 players. </li>
        <li>Unique Gameplay: Players declare points before melding cards.</li>
      </ul>
      </div>

      <div className={styles.content}>
      <h3>Deals Rummy</h3>
      <p>Win a set number of deals to secure victory in this variant.</p>
      <ul>
        <li>Free Chips: Use up to 5000 free chips to play deals rummy.</li>
        <li>Number of Players: Tables can accommodate 2 to 6 players. </li>
        <li>Unique Gameplay: Win by melding cards and declaring your hand.</li>
      </ul>
      </div>

      <div className={styles.content}>
      <h3>How to Play Free Rummy Games on Vyka Rummy</h3>
      <p>Follow these steps to enjoy free rummy games on Vyka Rummy:</p>
      <ol>
        <li>Download and Install Vyka Rummy App</li>
        <li>Register for a Free Account</li>
        <li>Navigate to Practice Games Lobby</li>
        <li>Select the Variant and Start Playing</li>
      </ol>
      </div>

      <div className={styles.content}>
      <h3>Benefits of Playing Free Rummy Online on Vyka Rummy</h3>
      <ul>
        <li>Learn Rummy Skills: Develop and hone your rummy skills.</li>
        <li>Live Practice: Compete against real players in a live environment.</li>
        <li>Understand Variants: Explore different rummy variants offered by Vyka Rummy.</li>
        <li>Explore Game Features: Learn how to utilize features like hand analyzer and chat box.</li>
        <li>Experience Live Gameplay: Feel the excitement of drop and pick cards on a game table.</li>
        <li> Play Unlimited Without Cash: Enjoy unlimited games without spending money.</li>
        <li>Stress Buster: Relax and experience the thrill without financial risk.</li>
      </ul>
      </div>

      <div className={styles.content}>
      <h3>Conclusion </h3>
      <p>Playing free rummy games on Vyka Rummy is an excellent way to learn and enjoy the game without the pressure of real money. Whether you're a beginner or an experienced player, Vyka Rummy offers a platform to enhance your rummy skills and have fun. Download Vyka Rummy today to start your rummy journey!</p>
      </div>
    </div>
  );
}

export default PractiseGames;
