import React, { useEffect } from 'react';
import styles from "../winmoneygameapp/wmga.module.scss"

const HoWToPlayPoolRummyOnVykaRummy = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
  return (
    <div className={styles.thirRummy_cont}>
      <h3> How to Play Pool Rummy on Vyka Rummy</h3>

      <div className={styles.content}>
      <p>Pool Rummy is a captivating variation of the classic Indian card game Rummy, suitable for 2-6 players. Learn the complete gameplay of Pool Rummy with Vyka Rummy.</p>
      </div>

      <div className={styles.content}>
      <h3>Game Setup</h3>
      <ul>
        <li>Each player is dealt 13 cards from a standard deck of 52 cards.</li>
        <li>The remaining cards are placed face down as the stock pile.</li>
      </ul>
      </div>

      <div className={styles.content}>
        <h3> Gameplay</h3>
        <p>Follow these steps to play Pool Rummy:</p>
      </div>

      <div className={styles.content}>
      <h3>Step 1: Dealing the Cards</h3>
      <ul>
        <li>The dealer distributes 13 cards to each player.</li>
      </ul>
      </div>

      <div className={styles.content}>
      <h3>Step 1: Dealing the Cards</h3>
      <ul>
        <li>The dealer distributes 13 cards to each player.</li>
      </ul>
      </div>

      <div className={styles.content}>
      <h3>Step 2: Taking Turns</h3>
      <ul>
        <li>Players draw one card from the stock or discard pile.</li>
        <li>Meld cards into sets (three or four of the same rank) or sequences (three or more cards of the same suit in consecutive order). </li>
        <li>After melding, discard one card to continue the game.</li>
      </ul>
      </div>

      <div className={styles.content}>
      <h3>Step 3: Winning the Game</h3>
      <ul>
        <li>The objective is to meld all cards into valid sets and sequences and declare "Rummy!"</li>
      </ul>
      </div>

      <div className={styles.content}>
      <h3> Pool Rummy Rules</h3>
      <p>Understand the basic rules of Pool Rummy:</p>
      <ul>
        <li>Meld only cards drawn from the stock or discard pile.</li>
        <li>Avoid picking up and discarding the same card.</li>
        <li>Do not use Jokers (wild cards) as part of a set.</li>
        <li>Only one set of the same rank and one sequence of the same suit are allowed.</li>
      </ul>
      </div>

      <div className={styles.content}>
      <h3> Pool Rummy Rules</h3>
      <p>Understand the basic rules of Pool Rummy:</p>
      <ul>
        <li>Meld only cards drawn from the stock or discard pile.</li>
        <li>Avoid picking up and discarding the same card.</li>
        <li>Do not use Jokers (wild cards) as part of a set.</li>
        <li>Only one set of the same rank and one sequence of the same suit are allowed.</li>
      </ul>
      </div>

      <div className={styles.content}>
      <h3>Pool Rummy Scoring</h3>
      <ul>
        <li>The first player to declare "Rummy!" wins the round.</li>
        <li>Penalty points are awarded to remaining players based on the cards in their hands.</li>
      </ul>
      </div>

      <div className={styles.content}>
      <h3>Penalty Points Calculation</h3>
      <ul>
        <li>Face cards (Jack, Queen, King): 10 points each</li>
        <li>Aces: 15 points each.</li>
        <li>Other cards: Their numerical value</li>
      </ul>
      </div>

      <div className={styles.content}>
      <h3>Ending the Game</h3>
      <ul>
        <li>The game concludes when a player accumulates a set number of penalty points.</li>
        <li>The player with the lowest penalty points wins the game.</li>
      </ul>
      </div>

      <div className={styles.content}>
      <h3>Variations of Pool Rummy</h3>
      <p>Explore different variations of Pool Rummy:</p>
      <ul>
        <li>Double Pool Rummy: Reach 200 penalty points to win.</li>
        <li>Cutthroat Pool Rummy: Players are eliminated upon reaching a specific point threshold.</li>
        <li>Partners Pool Rummy: Play in teams, aiming for the lowest collective penalty points.</li>
      </ul>
      </div>

      <div className={styles.content}>
      <h3>Tips to Excel in Pool Rummy</h3>
      <p>Enhance your gameplay with these strategic tips:</p>
      <ul>
        <li>Keep your hand size minimal to avoid accumulating high-value cards.</li>
        <li>Monitor opponents' discards to predict their card sequences.</li>
        <li>Discard high-value cards strategically.</li>
        <li>Declare "Rummy!" swiftly upon completing melds to prevent opponents from winning.</li>
      </ul>
      </div>

      <div className={styles.content}>
      <h3>What is 101 Pool Rummy on Vyka Rummy?</h3>
      <p>101 Pool Rummy is an exciting variant played with 13 cards. The objective is to score the least number of points. Players are eliminated upon reaching 101 points, and the player with the lowest score at the end wins.</p>
      </div>

      <div className={styles.content}>
      <h3>How to Play 201 Pool Rummy?</h3>
      <h3>Learn the rules of 201 Pool Rummy:</h3>
      <ul>
        <li>Deal 13 cards to each player.</li>
        <li>Draw from the stock or discard pile to meld sequences and sets.</li>
        <li>Declare when all cards are melded. Validate the hand to win or accumulate penalty points.</li>
      </ul>
      </div>

      <div className={styles.content}>
      <h3>Example of 201 Pool Rummy Game</h3>
      <h3>Visualize a scenario in 201 Pool Rummy</h3>
      <ul>
        <li>Player A melds all cards into valid sets and sequences.</li>
        <li>Other players' scores are based on unmelded cards.</li>
        <li>The player with the lowest score wins.</li>
      </ul>
      </div>

      <div className={styles.content}>
      <p> Pool Rummy Scoring System on Vyka Rummy</p>
      <h3>Understand the scoring system in Pool Rummy:</h3>
      <ul>
        <li>Calculate points based on card values and discard management.</li>
        <li>Face cards have fixed point values, while numbered cards retain their face value.</li>
      </ul>
      </div>

      <div className={styles.content}>
      <h3>Pool Rummy Winning Calculation</h3>
      <p>Explore how winning is calculated in Pool Rummy:</p>
      <ul>
        <li>Player with the least penalty points at the game's conclusion emerges as the winner.</li>
        <li>The prize pool is distributed accordingly based on scores and eliminations.</li>
      </ul>
      </div>
      <div>Master Pool Rummy on Vyka Rummy, employing strategy and skill to triumph over your opponents!</div>
    </div>
  );
}

export default HoWToPlayPoolRummyOnVykaRummy;
