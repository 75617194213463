import React, { useEffect } from 'react';
import styles from "../winmoneygameapp/wmga.module.scss"

const GameOfSkills = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className={styles.thirRummy_cont}>
      <h3>Exploring the Legality and Popularity of Games of Skill in India</h3>

      <div className={styles.content}>
        <p>In India, there is a distinct category of games known as "games of skill" that test players' mental abilities rather than relying on luck. Unlike games of chance, where luck plays a significant role, games of skill require players to demonstrate knowledge and expertise. The Indian Supreme Court has deemed these games legal in most states of India as they are not considered gambling. Some popular games of skill in India include chess, rummy, poker, bridge, and fantasy sports.</p>
      </div>

      <div className={styles.content}>
        <h3>Legal Status and Rise of Online Skill Games for Money</h3>
        <p>
          Online skill games have been gaining popularity in India due to their accessibility and the potential for players to earn money while having fun. Skill-based games involve expertise or knowledge that one can develop through practice and training, unlike games of chance, which are illegal in India. Various Indian courts have recognized the legality of playing skill games online for cash or prizes, emphasizing that they are skill-based and not chance-based. However, laws and regulations concerning skill games online vary by state in India, requiring players to be aware of the laws applicable in their particular state before engaging in online gaming for money.
        </p>
      </div>

      <div className={styles.content}>
        <h3>Supreme Court Verdict on Games of Skill</h3>
        <p>The gaming industry in India has experienced significant growth in recent years, with experts predicting it will reach $8.2 billion by 2027. The legality of games, particularly in the real-money gaming (RMG) sector, has been a subject of debate. In 2022, the Karnataka and Tamil Nadu governments requested that the Supreme Court regulate fantasy sports due to concerns over the impact on crime rates and mental health. The Supreme Court will determine whether online and fantasy games are skill-based or chance-based. Indian law recognizes games of skill as legal while games of chance are illegal.</p>
      </div>

      <div className={styles.content}>
        <h3>Differentiating Game of Skill & Game of Luck</h3>
        <p>Games of skill rely on the player's skill, strategy, and knowledge to influence the outcome, whereas games of luck are predominantly determined by chance with minimal player control. Examples of games of skill include chess, poker, and fantasy sports, while games of luck include slot machines, roulette, and bingo. Indian law generally permits games of skill but prohibits games of luck, with exceptions like state-run lotteries.</p>
      </div>

      <div className={styles.content}>
        <h3>Assessment of Vyka Rummy as a Game of Skill</h3>
        <p>Vyka Rummy is a turn-based card-matching game that involves strategic decision-making, requiring players to form two sequences of three cards each. Players must use their skills to create valid sequences and anticipate and block opponents' moves to win. The game mechanics and rules demonstrate a significant level of skill, strategic thinking, and decision-making.</p>
      </div>

      <div className={styles.content}>
        <h3>Skills Required to Play a Sequence Game</h3>
        <p>To excel in Sequence games, players need a combination of skills:</p>
        <ol>
          <li><span>Strategy:</span>Players must use strategic thinking and planning to select the right cards, block opponents' moves, and anticipate future plays.</li>
          <li><span>Knowledge:</span>Understanding the game rules and how to use cards to create sequences is crucial.</li>
          <li><span>Practice:</span>Consistent practice improves gameplay and enhances chances of winning.</li>
          <li><span>Attention to Detail:</span>Players must be attentive to cards played by both themselves and opponents.</li>
          <li><span>Memory Skills:</span>Remembering played cards and their positions aids decision-making.</li>
          <li><span>Decision-Making Skills:</span>Quick and confident decisions based on available cards and opponents' moves are essential.</li>
          <li><span>Flexibility and Adaptability:</span>Players must adapt strategies to changing game circumstances.</li>
        </ol>
      </div>

      <div className={styles.content}>
        <h3>Benefits of Playing Games of Skill Like Sequence Games</h3>
        <ol>
          <li><span>Non-Addictive:</span> Unlike gambling, games of skill are less addictive
            since they rely on skill and strategy.</li>
          <li><span>Skill Development:</span> Playing sequence games enhances critical
            thinking, problem-solving, and decision-making skills.</li>
          <li><span>Legal:</span>As per Indian law, games of skill are legal and can award cash
            prizes.</li>
          <li><span>Play Limitations:</span>Specific rules and limitations make games engaging
            and challenging, reducing the risk of addictive behaviors.</li>
        </ol>
      </div>

      <div className={styles.content}>
        <p>You can also explore other real-money game variants like Rummy and
          Poker on vykagames.com</p>
      </div>
    </div>
  );
}

export default GameOfSkills;
