import React from 'react';
import styles from "../home/home.module.scss"
import logo from "../../assets/images/logo.png"
import logo1 from "../../assets/images/logo1.png"
import phoneImg from "../../assets/images/phone.png"
import mailImg from "../../assets/images/mail.png"
import { GiHamburgerMenu } from "react-icons/gi";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link, useNavigate } from 'react-router-dom';
import { IoMdDownload } from "react-icons/io";

const Navbar = (scrollHeight: any) => {

  const navigate = useNavigate()
  console.log("scrollHeight", scrollHeight.scrollHeight);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (e: any) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRouteClick = () => {
    navigate("/")
  }

  return (
    <nav className={`${styles.header_cont} ${scrollHeight.scrollHeight > 35 && styles.header_cont1}`}>
      <div className={styles.logo_cont} onClick={handleRouteClick}>
        <img  src={logo1} alt='logo1' className={styles.logo_img1} />
        <img  className={styles.logo_img} src={logo} alt='logo' />
      </div>
      <div className={styles.nav_cont}>
        <ul className={styles.link_lists}>
          <li className={styles.list}><a className={styles.listLink} href="#products">PRODUCTS</a></li>
          <li className={styles.list}><a className={styles.listLink} href="#ourMission">OUR MISSION</a></li>
          <li className={styles.list}><a className={styles.listLink} href="#experience">EXPERIENCE</a></li>
          <li className={styles.list}><a className={styles.listLink} href="#aboutUs">ABOUT US</a></li>
          <li className={styles.list}><a className={styles.listLink} href="#workWithUs">WORK WITH US</a></li>
          <li className={styles.list}><a className={styles.listLink} href="#contact">CONTACT</a></li>
        </ul>
        <div className={styles.hamburger}>
          <Button
            id="demo-positioned-button"
            aria-controls={open ? 'demo-positioned-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={(e) => handleClick(e)}
            style={{ color: "#fff" }}
          >
            <GiHamburgerMenu style={{ fontSize: "1.5rem" }} />
          </Button>

          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}

            style={{ color: "#111" }}
          >
            <MenuItem onClick={handleClose}><a className={styles.navLink} href="#products">PRODUCTS</a></MenuItem>
            <MenuItem onClick={handleClose}><a className={styles.navLink}href="#ourMission">OUR MISSION</a></MenuItem>
            <MenuItem onClick={handleClose}><a className={styles.navLink}href="#experience">EXPERIENCE</a></MenuItem>
            <MenuItem onClick={handleClose}><a className={styles.navLink}href="#aboutUs">ABOUT US</a></MenuItem>
            <MenuItem onClick={handleClose}><a className={styles.navLink}href="#workWithUs">WORK WITH US</a></MenuItem>
            <MenuItem onClick={handleClose}><a className={styles.navLink}href="#contact">CONTACT</a></MenuItem>
          </Menu>
        </div>
        {/* <div className={styles.contact_options}>
          <div className={styles.contact_optionsImg}><img className={styles.c_img} src={phoneImg} alt='phoneImg' /></div>
          <div className={styles.contact_optionsImg}><img className={styles.c_img} src={mailImg} alt='mailImg' /></div>
        </div> */}
        <div className={styles.downloadApp}>
         <Link className={styles.login_link} to="https://games.vykagames.com" > Login </Link>
          </div>
      </div>
    </nav>
  );
}

export default Navbar;
