import React from 'react';
import styles from "./workWithUs.module.scss"
import cards from "../../assets/images/cards.png"

const WorkWithUs = () => {
    return (
        <section className={styles.work_cont} id='workWithUs'>
            <div className={styles.work_subCont}>
                <div className={styles.cards_cont}>
                    <img className={styles.card_img} src={cards} alt="cards" />
                </div>
                <div className={styles.wContent_cont}>
                    <header className={styles.w_header}>WORK WITH US!!</header>
                    <div className={styles.wText_cont}>
                        <p>Welcome to Vyka Games, a leading gaming company dedicated to crafting immersive real money gaming experiences. Specializing in popular games like Rummy and Ludo, we offer a dynamic platform where players can engage in thrilling competitions and win real cash prizes. At Vyka Games, we not only prioritize the satisfaction of our players but also the well-being of our employees.
                        </p>
                        <p>Our commitment to fostering a positive work culture is unwavering, as we believe that a supportive and inclusive environment leads to innovation and success. We are proud to offer comprehensive employment policies that prioritize employee welfare, including competitive compensation packages, flexible work arrangements, and opportunities for growth and development. Moreover, at Vyka Games, we understand the importance of a healthy work-life balance and strive to create a workplace where our team members feel valued, motivated, and inspired to contribute their best. Join us on this exciting journey where gaming meets excellence, and experience the charm of being a part of the Vyka Games family.</p>
                    </div>
                    <p className={styles.notify}>*Current Positions available*</p>
                </div>
            </div>
        </section>
    );
}

export default WorkWithUs;
