import React from 'react';
import styles from "./experience.module.scss"

const Experience = () => {
    return (
        <section className={styles.exp_cont} id='experience'>
            <div className={styles.exp_subCont}>
            <div className={styles.expContent_cont}>
                <header className={styles.exp_header}>Experience</header>
                <div className={styles.expText_cont}>
                    <p className={styles.exp_text}>Vyka Games shatters the mold of conventional work environments, offering a future-focused approach that prioritizes results and empowers its team. This translates to a dynamic work environment that fosters a rich work-life balance. Employees flourish in a culture of flexibility, where working anytime, anywhere is encouraged as long as goals are achieved. This autonomy allows individuals to craft a work style that seamlessly integrates with their personal lives.
                    </p>
                    <p className={styles.exp_text}>Whether it's tackling a project during peak focus hours in the quiet of the morning or attending a child's school play without sacrificing productivity, Vyka Games fosters a work-life harmony that fuels creativity and well-being. This remote-friendly approach, supported by cutting-edge technology and collaborative tools, dismantles the boundaries of a traditional office, creating a vibrant and enriching professional experience that extends far beyond the confines of a traditional office setting.</p>
                    <p className={`${styles.exp_text} ${styles.exp}`}><span>Vyka Games:</span> Thrive Where You Work Best.</p>
                </div>
            </div>
            </div>
        </section>
    );
}

export default Experience;
