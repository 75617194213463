import React from 'react';
import styles from "./aboutUs.module.scss"
import director1 from "../../assets/images/director1.png"
import director2 from "../../assets/images/director2.png"

const AboutUs = () => {
    return (
        <section className={styles.abUs_cont} id='aboutUs'>
            <div className={styles.abUsContent_cont}>
                <header className={styles.ab_header}>ABOUT US</header>
                <p className={styles.intro}>Sree Vyka Games Pvt. Ltd. is a gaming platform provider dedicated to crafting exceptional customer experiences and unparalleled reliability. We are passionate about creating a world-class gaming environment where players can come together, have fun, and compete in a fair and secure setting.</p>
            </div>
            {/* <div className={styles.abComp_cont}>
                <div className={styles.directors_cont}>
                    <div className={styles.direc_img}><img src={director1} alt="director1" /></div>
                    <div className={styles.ab_direc}>
                        <header className={styles.direc_name}>Vydehi Kanchinadham,</header>
                        <p className={styles.direc_details}>a director at Sree Vyka Games, is passionate about creating a superior user experience for the company's customers. Her focus lies on crafting platforms that are both rich in features and easy to navigate. This commitment to readability and customer experience ensures that users can interact with Sree Vyka Games' products in a smooth and enjoyable way.</p>
                    </div>
                </div>
                <div className={styles.directors_cont}>
                    <div className={styles.direc_img}><img src={director2} alt="director2" /></div>
                    <div className={styles.ab_direc}>
                        <header className={styles.direc_name}>Harshith Kanchinadham,</header>
                        <p className={styles.direc_details}>is a visionary leader in the gaming industry, currently serving as the director of Sree Vyka Games. His mission is to cultivate a positive and enriching customer experience by implementing cutting-edge technology within the games he develops. This focus on both user satisfaction and technological innovation positions Sree Vyka Games as a frontrunner in the gaming market.</p>
                    </div>
                </div>
            </div> */}
            <div className={styles.custExp_cont}>
                <header className={styles.custExp_header}>Customer Experience First</header>
                <div className={styles.cust_content}>
                    <p>At Sree Vyka Games, we prioritize the needs of our players above all else. We strive to provide a seamless and enjoyable gaming experience from the moment you sign up. This commitment is reflected in our:
                    </p>
                    <p>Intuitive and user-friendly platform: Our platform is designed to be easy to navigate, regardless of your technical expertise.</p>
                    <p>Wide variety of games: We offer a diverse selection of games to cater to all interests and preferences.</p>
                    <p>Responsive customer support: Our dedicated support team is always available to assist you with any questions or concerns you may have.</p>
                </div>
            </div>
        </section>
    );
}

export default AboutUs;
