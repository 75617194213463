import React, { useState } from 'react';
import styles from "./footer.module.scss"
import emailImg from "../../assets/images/mail.png"
import phImg from "../../assets/images/phone.png"
import { Link, useSearchParams } from 'react-router-dom';
import visa from "../../assets/images/footer/visa.webp"
import masterCard from "../../assets/images/footer/Master.webp"
import aigf from "../../assets/images/footer/AIGF.webp"
import legal from "../../assets/images/footer/100.webp"
import ageRestict from "../../assets/images/footer/18.webp"
import startUpIndia from "../../assets/images/footer/RNG.webp"
import rng from "../../assets/images/footer/RNG2.webp"
import secure from "../../assets/images/footer/SSL.webp"

const Footer = () => {
    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    const [queries] = useSearchParams();
    const [isEmbed, setIsEmbed] = useState(queries.get('embed'))

    return (
        <>
            {isEmbed === null ?
                <section className={styles.footer_cont} id='contact'>
                    <div className={styles.ab_cont}>
                        <header className={styles.footer_header}>CONTACT</header>
                        <div className={styles.footerCont_cont}>
                            <div className={styles.footer_content}>Thank you for your interest in Vyka Games! Whether you have a question, feedback, or simply want to get in touch, we're here to assist you. Please feel free to reach out to us using any of the following methods:
                            </div>
                            <div className={styles.footer_fieldsCont}>
                                <div className={styles.footer_fieldSubCont}>
                                    <p className={styles.ffield_header}>Home</p>
                                    <ul className={styles.footer_field}>
                                        <li><Link to="/rummy/gameofskills">Game of skills</Link></li>

                                        {/* <li><Link   to=''>Real Money Winning App</Link> </li> */}

                                        {/* <li><Link   to=''>Rummy Game</Link></li> */}

                                        {/* <li><Link   to=''>Contact Us</Link></li> */}

                                        <li><Link to='/terms&conditions'>
                                            Terms And Conditions
                                        </Link>
                                        </li>
                                        <li><Link to='/privacypolicy'>
                                            Privacy Policy                                </Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className={styles.footer_fieldSubCont}>
                                    <p className={styles.ffield_header}>Rummy Game</p>
                                    <ul className={styles.footer_field}>
                                        <li><Link to='/rummy/13cardrummy'>13 Card Rummy</Link></li>

                                        <li><Link to='/rummy/onlinerummycashgames'>Cash Game Online</Link> </li>

                                        <li><Link to='/rummy/practicegamesonvykarummy'>Free Rummy Practice Games</Link></li>

                                        <li><Link to='/rummy/rummyrulesguide'>How to Play Rummy Rules</Link></li>

                                        <li><Link to='/rummy/howtoplayindianrummyonlinewithvykarummy'>
                                            Indian Rummy
                                        </Link>
                                        </li>

                                        {/* <li><Link   to=''>
                                Rummy App Download                                </Link>
                                </li> */}
                                    </ul>
                                </div>
                                <div className={styles.footer_fieldSubCont}>
                                    <p className={styles.ffield_header}>Ludo Game</p>
                                    <ul className={styles.footer_field}>
                                        <li><Link to='/ludo/aboutludoonline'>About Ludo Online</Link> </li>
                                        <li><Link to='/ludo/ludorules'>Ludo Rules</Link></li>
                                    </ul>
                                </div>
                                <div className={styles.footer_fieldSubCont}>
                                    <p className={styles.ffield_header}>Help</p>
                                    <ul className={styles.footer_field}>
                                        {/* <li><Link   to=''>Add Cash Limits</Link></li> */}

                                        <li><Link to='/rummy/tdsonwinnings'>TDS on Winning</Link> </li>

                                        {/* <li><Link   to=''>Withdrawing Cash</Link></li> */}

                                        {/* <li><Link   to=''>Game Cancellation And Settlement Policy</Link></li> */}

                                        {/* <li><Link   to='/terms&conditions'>
                                Terms And Conditions
                                </Link>
                                </li> */}
                                        <li><Link to='/rummy/faq'>FAQ's</Link>
                                        </li>

                                        {/* <li><Link   to=''>
                                Contact Us                                </Link>
                                </li> */}
                                    </ul>
                                </div>
                                <div className={styles.SecurityCer_cont}>
                                    <p className={styles.ffield_header}>Security & Certification</p>
                                    <div className={styles.SecurityCer_fieldCont}>
                                        <div className={styles.fImg_cont}><img className={styles.FImg} src={visa} alt="visa" /></div>
                                        <div className={styles.fImg_cont}><img src={masterCard} alt="masterCard" className={styles.FImg} /></div>
                                        {/* <div className={styles.fImg_cont}><img src={aigf} alt="aigf" className={styles.FImg}/></div> */}
                                        <div className={styles.fImg_cont}><img src={legal} alt="legal" className={styles.FImg} /></div>
                                        <div className={styles.fImg_cont}><img src={ageRestict} alt="ageRestict" className={styles.FImg} /></div>
                                        {/* <div className={styles.fImg_cont}><img src={startUpIndia} alt="startUpIndia" className={styles.FImg}/></div> */}
                                        {/* <div className={styles.fImg_cont}><img src={rng} alt="rng" className={styles.FImg}/></div> */}
                                        <div className={styles.fImg_cont}><img src={secure} alt="secure" className={styles.FImg} /></div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.contact_cont}>
                                <div className={styles.contact_subCont}>
                                    <div className={styles.contact_abCont}>
                                        <div className={styles.email_cont}>
                                            <img src={emailImg} alt="emailImg" />
                                            <span>grievance@vykagames.com</span>
                                        </div>
                                        <div className={styles.phno_cont}>
                                            <img src={phImg} alt="phImg" />
                                            <span>+91 7977438674</span>
                                        </div>
                                    </div>
                                    <div className={styles.dateTime}><span style={{ color: "#F0AB2C" }}> Monday to Friday</span>&nbsp; <span>-</span>&nbsp;9:00 AM to 6:00 PM</div>
                                </div>
                                <div className={styles.address_cont}>
                                    <div className={styles.add}>Address:</div>
                                    <div className={styles.addContent}>
                                        <div className={styles.c_name}>
                                            SREE VYKA GAMES PRIVATE LIMITED</div>
                                        4-6-137/10 SAI NAGAR Allwyn Colony Road
                                    </div>
                                    <div className={styles.addContent}>Hyderabad Medchal Malkajgiri, Telangana - 500072
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <footer className={styles.footer}><span>Copyright © 2024 Vyka Games. All Rights Reserved.</span> <span> <Link onClick={scrollToTop} to='/privacypolicy'>Privacy Policy</Link> | <Link onClick={scrollToTop} to='/terms&conditions'> Terms&Conditions </Link></span> </footer>
                </section>
                : ""}
        </>

    );
}

export default Footer;
