import React, { useEffect, useState } from "react";
import styles from "./privacyPolicy.module.scss"
import logo from "../../assets/images/logo.png"
import { useNavigate, useSearchParams } from "react-router-dom";

export default function PrivacyPolicy() {

    const navigate = useNavigate()
    const [queries] = useSearchParams();
    const [isEmbed, setEmbed] = useState(queries.get('embed'))
    const handleRouteClick = () => {
        if (isEmbed === null) {
            navigate("/")
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return (
        <>
            <div className={`${styles.privacyPolicy_cont} ${isEmbed && styles.privacyPolicy_cont1}`}>
                {
                    isEmbed ? "" : <div style={{ cursor: "pointer" }}
                        onClick={handleRouteClick}
                    ><img style={{ width: "80px" }} src={logo} alt="logo" /></div>
                }
                
                <header className={`${isEmbed ? styles.privacyPolicy_header1:styles.privacyPolicy_header}`}>Privacy Policy</header>
                <div className={styles.content}>
                    <p>
                        Sree Vyka Games Private Limited, a company incorporated under the laws of India and having its
                        registered office at 4-6-137/10 Sai Nagar Allwyn Colony Road,Hyderabad Medchal Malkajgiri, Telangana. (Sree Vyka Games referred to as “Vyka Games,” “we,” “us,” or “our”) is the operator of the website www.vykagames.com (“the Site”).

                        The Company is incorporated as a Company under the Companies Act, 2013 and provides various skill
                        game-based
                        services to you in India as set out in the End User License Agreement (“Agreement”).

                        You acknowledge that the trademark Vyka Games (the “Brand”) is owned by the Company. The term
                        ‘You/Your’
                        or “User” refers to the user or viewer of the site.

                        We are committed to maintaining the confidentiality of the Personal Information (as defined below)
                        collected
                        by us by safeguarding it utilizing best in class software and hardware architecture, techniques, methods
                        and
                        procedures which allow you to enjoy the Services available on the Site in the knowledge that your
                        privacy is
                        safeguarded. We will comply with all applicable legal, regulatory or administrative requirements of any
                        governmental authorities including but not limited to the terms of the Information Technology Act, 2000
                        and
                        rules framed thereunder as amended, and the rules and regulations thereunder.

                        Please note that the Agreement takes precedence over any conflicting provision of this Privacy Policy.
                        Unless otherwise defined herein, capitalised terms shall have the meaning given to them in the
                        Agreement.
                    </p>
                </div>
                <ol>
                    <li className={styles.content}>
                        <span>Consent to Privacy Policy</span>
                        <p>
                            By using the Services, you agree to the terms of this Privacy Policy. We reserve the right to amend this
                            Privacy Policy and changes will come into effect within 14 days of such change being published on the
                            Site.
                            Your continued use of the Service constitutes your agreement to this Privacy Policy and any amendments
                            to
                            the same thereinafter. The purpose of this Privacy Policy is to enable you to understand how we collect,
                            store, use and safeguard your Personal Information and for you to understand your rights in relation to
                            that
                            information. References to defined terms hereunder, if not specified, shall have the same meaning as set
                            out
                            in the Agreement.
                        </p>
                    </li>

                    <li className={styles.content}>
                        <span>What Information do We collect?</span>
                        <p> Our primary goal of collecting your information is to provide You a safe, efficient, smooth and
                            customized experience. This allows us to provide services and features that most likely meet Your needs, and to makeYour experience safer and easier.
                            When You visit this Site You may provide us with Your:
                            
                • Personal Information: Name, images, mobile number, email id and content which You knowingly disclose
                that
                is collected on an individual basis.

                • Billing Information: Complete name; complete address including city and pin code; contact number and
                email
                address; and the GSTN to create invoices on Your behalf. This Billing information as disclosed by You
                and
                collected by Us, where applicable and on being requested by You, will be used to make payments and/or
                raise
                any invoices, if any.

                • Automatic Information: We store all such information which automatically gets accessible to Us by the
                virtue of visiting the Site or downloading our application on Your device, like location, email-id etc.
                (app
                permissions). We receive and store certain types of information whenever You interact with other users.
                For
                example, like many Web sites, we use Cookies and we obtain certain types of information when Your Web
                browser accesses our Site or advertisements and other content served by or on behalf of our Company.

                Cookie is a small data file that Our server sends to Your browser when You visit the Site (“Cookies”).
                The
                use of cookies helps us to assist Your use of certain aspects of the Site. You can delete cookies at any
                time or You can set Your browser to reject or disable Cookies. We may use information from cookies for
                the
                following purposes:

                • Traffic monitoring;

                • IP address from which You access the Site;

                • type of browser and operating system used to access the Site;

                • date and time of Your access to the Site;

                • pages you visit

                • Internet address of the website from which you accessed the site to recognize repeat visitors for
                statistical / analytical purposes.

                • Stored Information: We store all such data which are shared by You or by a third party on Your behalf
                either at the time of installation of the Site or at any other later stage on Our Site such as image
                files,
                documents etc.

                Personal Information, Automatic Information, Stored Information and Shared Information shall
                individually be
                referred as “Personal Information”, “Automatic Information”, “Stored Information” and “Shared
                Information”
                and collectively as “Information”.
                        </p>

                    </li>



                    <li className={styles.content}>
                        <span>Use of Information</span>
                        <p>
                        • We use Your Information for understanding Users in such a way as to provide better Services at our
                Site;

                • We use Your Information to customize Your experience; detect and protect Us against error, fraud and
                other
                criminal activity; enforce Our terms and conditions; and as otherwise described to You at the time of
                collection;

                • We use Your Information continually to improve Our Product and Service offerings; and
                        </p>
                    </li>

                    <li className={styles.content}>
                        <span>Eligibility</span>
                        <p>
                        Services on the Platform would be available to India. Persons who are “incompetent to contract” within
                the
                meaning of the Indian Contract Act, 1872 including minors, undischarged insolvents etc. are not eligible
                to
                use the Platform. We reserve the right to terminate or refuse Your registration, or refuse to permit
                access
                to the Site, if it is discovered or brought to its notice that You are a minor.
                        </p>
                    </li>

                    <li className={styles.content}>
                        <span>Deposit and withdrawal processing</span>
                        <p>
                        In the course of making a deposit, You consent to providing us, or our duly appointed third party payment
                processors or our service providers, with the deposit method information and other relevant financial
                information (example: credit card number, debit card number and other payment instrument information).

                This information in conjunction with Your other personal information might be used, either by us or by
                third
                party payment processors appointed by us or service providers to clear financial transactions executed
                by
                you. You are required to advise us of, and update on your User Account, any relevant changes to your
                personal information.
                        </p>
                    </li>

                    <li className={styles.content}>
                        <span>What types of information are not allowed on Site?</span>
                        <p>
                        • Nudity or other sexually suggestive content;
                • Hate speech;
                • credible threats or direct attacks on an individual or group;
                • Content that contains self-harm or excessive violence;
                • Fake or impostor profiles; and
                • Spam.
                        </p>
                    </li>

                    <li className={styles.content}>
                        <span>Technical and functional management of the Site</span>
                        <p>
                        When You use Our Site, We may also process technical data such as Your IP-address, Device ID or
                MAC-address,
                and information about the manufacturer, model, and operating system of Your device. We use this data to
                enable us to deliver the functionalities of the Site, resolve technical difficulties, and provide You
                with
                the correct (prevent the transmission of spam, viruses and/or unlawful, defamatory, obscene or otherwise
                undesirable material or content) and most up to date version of the Site.
                        </p>
                    </li>

                    <li className={styles.content}>
                        <span>Security</span>
                        <p>
                        We takes Your security seriously and We take precautions to keep Your Information secure. We have put in
                place appropriate physical, electronic and managerial procedures to safeguard the information We
                collect.

                However, due to the open communication nature of the internet, We cannot guarantee that communications
                between You and Us, or information stored on Site, will be free from unauthorized access by third
                parties.
                Any transmission of information by You to Our Site is at Your own risk.
                        </p>
                    </li>

                    <li className={styles.content}>
                        <span>Legal Disclaimer</span>
                        <p>
                        We reserve the right to disclose Your personally identifiable information as required by law and when we
                believe that disclosure is necessary to protect Our rights and/or to comply with a judicial proceeding,
                court order or legal process served on Our Site.

                We may be required to share the aforesaid information with government authorities and agencies for the
                purposes of verification of identity or for prevention, detection, investigation including cyber
                incidents,
                prosecution and punishment of offences. You agree and consent to disclose Your Information, if so
                required
                under the applicable law.
                        </p>
                    </li>

                    <li className={styles.content}>
                        <span>Grievance Redressal</span>
                        <p>
                        If You find any discrepancies or have any grievances in relation to the collection, storage, use,
                disclosure
                and transfer of Your Personal Information under this Privacy Policy or other terms and conditions,
                please
                contact Us on info@vykagames.com to Mr. Bharadwaj [Grievance Officer: as per new data privacy laws, all
                complaints relating to personal data must be sent to the Grievance Officer ]
                        </p>
                    </li>

                    <li className={styles.content}>
                        <span>A Special Note about Children</span>
                        <p>
                        The Services under the Site is not directed towards children under 18 (eighteen), they are not allowed to
                register with or use the Service or provide personal data. If We discover that We have collected
                personal
                information from a child under 18, We will delete that information immediately.
                        </p>
                    </li>

                    <li className={styles.content}>
                        <span>Revisions to Privacy Policy</span>
                        <p>
                        We reserves the right to update and revise this Privacy Policy at any time. You can determine if this
                Privacy
                Policy has been revised since Your last visit by referring to the “Effective Date of the current Seller
                Privacy Policy” date at the end of this page.

                You should therefore review this Privacy Policy regularly to ensure that You are aware of its terms and
                amendments. Your use of our Site constitutes Your acceptance of the terms of the Privacy Policy as
                amended
                or revised by Us.
                        </p>
                    </li>

                    <li className={styles.content}>
                        <span>Governing Law</span>
                        <p>
                        The laws of the India, shall govern this Privacy Policy, regardless of Your location.
                You
                hereby expressly
                consent to exclusive jurisdiction and venue located is Mumbai, India, for all matters or disputes
                arising
                out of or relating to these Terms of Use or Your access or use of the Site.

                Any cause of action arising out of or related to these terms of use, or your use of the site must be
                brought
                by you within one (1) month of the date such cause of action first arose, or such cause of action is
                forever
                waived by you. each cause of action shall be adjudicated individually, and you agree not to combine your
                claim with the claim of any third party.

                “Effective Date of the current Seller Privacy Policy: April 20, 2023 Version #1”
                        </p>
                    </li>
                </ol>
            </div>
        </>
    );
}