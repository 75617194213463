import React, { useEffect } from 'react';
import styles from "../winmoneygameapp/wmga.module.scss"

const LudoRules = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className={styles.thirRummy_cont}>
            <h3>Ludo Rules</h3>

            <div className={styles.content}>
                <p>Ludo was created in India, did you know that? Ludo is mentioned in the Mahabharata, a classic Indian epic. The royal family, especially the monarchs, like to play this traditional board game, which has been played in every home on earth.</p>
                <p>Thanks to real money games, it is now also played online. But what are the rules of ludo? Don’t worry, we have got you covered for ludo game rules.</p>
            </div>

            <div className={styles.content}>
                <h3>QUICK LUDO
                    RULES AND COMMON TERMS USED</h3>
                <p>
                    The ludo rules mainly include rolling the dice and moving all the 4 pawns across the board to reach home. In this journey, a pawn must kill the opponent’s pawns, absorb their points, earn extra turns and even save their own selves to reach home. Once all the 4 pawns of a player reach home, the player wins. However, in online ludo, the ludo board game rules may change a little.
                </p>
                <p>This is because ludo online is a limited time period game. Each player gets a specific number of turns and a limited time to play their turns. If they cannot play their turns or miss their turns, they may end up losing the game and even the cash prize. In addition, rules to play ludo are also different online because each player gets a share of the pot money based on their ranks to reach home.</p>
            </div>

            <div className={styles.content}>
                <p>
                    While knowing the ludo game rules, it is important to know these terms too, which are:</p>
                <ul>
                    <li>The coloured puck or piece that you move across the board is called a “pawn”. A device that rolls and shows how many moves you will take is called the “dice”.</li>
                    <li>All four of your pawns must arrive in the coloured "house" on the board before the game is over.</li>
                    <li>All pawns are protected in the “safe areas” marked by the arrows on the tiles of the four house colours—Red, Blue, Green, and Yellow.</li>
                    <li>All pawns are “safe zones” or “star tiles” on tiles marked with stars. The board has 8 star tiles.</li>
                    <li>Now, read the ludo rules you are here for in detail. Also download this ludo rules pdf to easily access it and to share with friends & family</li>
                </ul>
            </div>

            <div className={styles.content}>
                <h3>Rules To Play Ludo Game</h3>
                <p>
                    Ludo was created in India, did you know that? Ludo is mentioned in the Mahabharata, a classic Indian epic.
                </p>
                <p>The royal family, especially the monarchs, like to play this traditional board game, which has been played in every home on earth.</p>
                <p>Thanks to real money games, it is now also played online.</p>
                <p>But what are the rules of ludo? Don’t worry, we have got you covered for ludo game rules. Quick Ludo Rules and Common Terms Used While knowing the ludo game rules, it is important to know these terms too, which are The coloured puck or piece that you move across the board is called a “pawn” A device that rolls and shows how many moves you will take is called the “dice”</p>
                <p>All four of your pawns must arrive in the coloured "house" on the board before the game is over</p>
                <p>All pawns are protected in the “safe areas” marked by the arrows on the tiles of the four house colours—Red, Blue, Green, and Yellow</p>
                <p>All pawns are “safe zones” or “star tiles” on tiles marked with stars.</p>
                <p>The board has 8 star tiles. Now, read the ludo rules you are here for in detail.</p>
                <p>Also download this ludo rules pdf to easily access it and to share with friends & family Rules To Play Ludo Game Here are the ludo game rules in english that you must know</p>
                <ul>
                    <li>You get 50 turns in a match Your pawn moves forward based on the number that appears on the cube 1 tile moved = 1 point If you land on a tile occupied by your opponent, you kill their pawn and absorb its score.</li>
                    <li>The killed pawn goes back to its starting position When pawns reach home, their scores are multiplied - 2x, 3x, 4x, and 5x for the 1st, 2nd, 3rd and 4th pawn, respectively If &gt;=2 pawns of one colour are on the same tile, they create a safe zone and cannot be killed You get an extra cube roll if you get a 6 on the cube or your pawn reaches home or you kill an opponent's pawn.</li>
                    <li>The player with the highest score wins the game.</li>
                    <li>The match ends when all 4 pawns of any player reach home or the timer expires, or 50 turns are over, whichever is earlier.</li>
                    <li>Ludo Rules Frequently Asked Questions FAQs What are the rules of ludo? The rules of ludo are fairly simple.</li>
                    <li>Just move your pawns as per the number on the dice to reach home before your opponent's. How are ludo board game rules different from other games?
                        Ludo is played with coloured pawns and dice.</li>
                    <li>To win, a player must move their pawns across the board to reach home before their opponent. What are the ludo rules for 4 sixes?</li>
                    <li>In ludo, 3 or more sixes in a row are not counted. What are some quick ludo rules?
                        The quick ludo rules include rolling the dice and moving all the pawns across the board to reach home and win the game. You can get your ludo game rules pdf here.</li>
                    <li>Now that you know it all, start playing ludo online on VyKa Ludo. Go to www.games.vykagames.com.com and download the app now.</li>
                </ul>
            </div>
        </div>
    );
}

export default LudoRules;
