import React, { useEffect } from 'react';
import styles from "../winmoneygameapp/wmga.module.scss"

const HowToplayDealsRummyOnVyka = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
  return (
    <div className={styles.thirRummy_cont}>
      <h3> How to Play Deals Rummy on Vyka Rummy </h3>

      <div className={styles.content}>
        <p>Get ready to play Deals Rummy with your group of 2 to 6 players and an equal stack of chips! Here's a step-by-step guide to mastering the gameplay of Deals Rummy on Vyka Rummy.</p>
      </div>

      <div className={styles.content}>
      <h3>Step 1: Choose a Table and Buy-in</h3>
      <ul>
        <li>Deals Rummy accommodates 2 to 6 players.</li>
        <li>Each player enters the game by buying in with a set number of chips, determining the stakes of the game.</li>
      </ul>
      </div>

      <div className={styles.content}>
      <h3>Step 2: Deal the Cards</h3>
      <ul>
        <li>Assign one player as the dealer.</li>
        <li>The dealer shuffles the deck and deals 13 cards to each player.</li>
        <li>Remaining cards are placed face down in a central pile.</li>
      </ul>
      </div>
      
      <div className={styles.content}>
      <h3>Step 3: Start the Card Game</h3>
      <ul>
        <li>The player to the left of the dealer begins.</li>
        <li>On a turn, draw from the pile or take the top discard.</li>
        <li>After drawing, discard a card to continue play.</li>
      </ul>
      </div>

      <div className={styles.content}>
      <h3>Step 1: Choose a Table and Buy-in</h3>
      <ul>
        <li>Deals Rummy accommodates 2 to 6 players.</li>
        <li>Each player enters the game by buying in with a set number of chips, determining the stakes of the game.</li>
      </ul>
      </div>

      <div className={styles.content}>
      <h3>Step 4: Form Melds</h3>
      <ul>
        <li>The objective is to create melds (sets or sequences) with your cards.</li>
        <li>Sets consist of three or four cards of the same rank, while sequences are three or more cards of the same suit in consecutive order.</li>
      </ul>
      </div>

      <div className={styles.content}>
      <h3>Step 5: Declare Your Cards</h3>
      <ul>
        <li>When you have at least one meld and no more than 7 unmelded cards, you can declare.</li>
        <li>Lay down your melds and discard remaining cards to declare.</li>
      </ul>
      </div>

      <div className={styles.content}>
      <h3>Step 6: Calculate Your Score</h3>
      <ul>
        <li>Successful declaration earns you the deal's chips.</li>
        <li>Unsuccessful declaration results in paying opponents a value based on your unmelded cards' total points.</li>
      </ul>
      </div>

      <div className={styles.content}>
      <h3>Step 7: Play the Next Deal</h3>
      <ul>
        <li>Rotate the dealer clockwise for the next round.</li>
        <li>Begin a new deal with fresh cards and continue until reaching a predetermined number of deals or chip accumulation.</li>
      </ul>
      </div>

      <div className={styles.content}>
      <h3>What Happens in Deals Rummy?</h3>
      <p>Deals Rummy is a rapid and strategic game where players race to declare melds first and win chips. Here's a recap of the game's progression:</p>
      <ul>
        <li>Players are dealt 13 cards each to start.</li>
        <li>Taking turns, players draw and discard cards to form melds.</li>
        <li>The first player to declare successfully wins the deal's chips.</li>
        <li>An unsuccessful declaration results in paying opponents based on unmelded card values.</li>
      </ul>
      <p>The game proceeds through multiple deals until reaching a set goal of deals or chip accumulation. </p>
      </div>
      
      <div className={styles.content}>
      <h3> Deals Rummy Scoring Explained</h3>
      <p>In Deals Rummy, scoring is crucial for determining winners and losers. Here's how the scoring system operates:</p>
      <ul>
        <li>Each card carries a specific point value:</li>
        <ul>
            <li>Ace and face cards: 10 points each</li>
            <li>Number cards: Face value (2-10)</li>
        </ul>
      </ul>
      </div>

      <div className={styles.content}>
        <p>To calculate a player's score:</p>
        <p>Score=Total value of unmelded cards</p>
      </div>    

      <div className={styles.content}>
        <h3>Points to Note:</h3>
        <ul>
            <li>Aces and face cards are worth 10 points each.</li>
            <li>Number cards are valued based on their face value.</li>
            <li>Jokers count as zero points in scoring.</li>
            <li>The maximum score is capped at 80 points.</li>
        </ul>
      </div> 

      <div className={styles.content}>
        <h3>Deals Rummy Winning</h3>
        <p>The winner of a Deals Rummy deal is determined by scoring:</p>
        <ul>
            <li>The player with the lowest score at the end of a deal collects all chips from opponents.</li>
        </ul>
      </div>


      <div className={styles.content}>
        <h3>Example:</h3>
        <table border={1}>
            <thead>
            <th>Player</th>
            <th>Hands</th>
            <th>Melds</th>
            <th>Score</th>
            </thead>

            <tbody>
                <tr>
                    <td>Player 1</td>
                    <td>♤2 ♤3 ♤4 ♤5 ♥️ 6 ♥️ 7 ♥️ 8 ♥️ 9 ♦️ 10 ♦️ Jack ♦️ Queen ♦️ King ♦️ Ace</td>
                    <td>Set: ♤2 ♤3 ♤4s</td>
                    <td>0</td>
                </tr>
                <tr>
                    <td>Player 2</td>
                    <td>|♤5 ♤6 ♤7 ♧8 ♥️ 9 ♥️ 10 ♥️ Jack ♤ Queen ♦️ Ace ♦️ King ♧2 ♧3</td>
                    <td>Set: ♤5 ♤6 ♤7 </td>
                    <td>30</td>
                </tr>
            </tbody>

        </table>
      </div>

      <div className={styles.content}>
        <h3>Scoring:</h3>
        <ul>
            <li>Player 1 wins with a score of 0 (no unmelded cards).</li>
            <li>Player 2's score is 30 (total value of unmelded cards).</li>
            <li>Player 1 collects all chips from Player 2.</li>
        </ul>
      </div> 


      <div className={styles.content}>
        <p>Play Deals Rummy confidently on Vyka Rummy using these guidelines and scoring strategies!</p>
      </div> 

    </div>
  );
}

export default HowToplayDealsRummyOnVyka;
