import React, { useEffect } from 'react';
import styles from "../winmoneygameapp/wmga.module.scss"

const GSTDeductions = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className={styles.thirRummy_cont}>
            <h3>GST Compliance and Deductions</h3>

            <div className={styles.content}>
                <p>In compliance with the amendments made in the Central Goods and Services Tax Act, 2017, Integrated Goods and Services Tax Act, 2017, the State Goods and Services Tax Act, 2017 and Rules made under the said Acts including Notifications issued under the said Acts (hereinafter collectively referred to as "GST Laws") related to taxability of online gaming, VyKa Games is obligated to collect GST at the rate of 28% (28% of deposit amount) from the amount deposited by players/users for playing on VyKa Games Platform.</p>
                <p>VyKa Games and the player/user agree that the amount deposited by the player/user in his/her VyKa Games account will be deemed to be inclusive of GST. Accordingly, the GST portion of the amount deposited by the player/user will be deducted by VyKa Games from the player's/user's user account to be paid to the Government.
                    In the event that VyKa Games decides, at its own and absolute discretion, to refund an amount deposited accidentally by a player/user, the amount will be refunded only after deducting GST payable on such amount, in accordance with the GST Laws. No amount will be refunded in full and any refund is subject to VyKa Games 's absolute discretion.</p>
            </div>

            <div className={styles.content}>
                <h3>Withdrawal Options</h3>
                <p>
                If a player who has positive net winnings wants to withdraw money from his/her withdrawable balance, the player can (1) either pay 30% TDS on the net winnings and withdraw the amount or (2) use the option to partially transfer the GST cashback/instant cash received from VyKa Games application to his/her Deposit balance and optimise the benefit.
                </p>
                <p>The total GST cashback that has been provided to the player (and is part of the player's net winnings) in a financial year can be fully adjusted or clawed back. If the player's net winnings are more than the total GST cashback received by the player in the financial year, the net winnings remaining after deducting the GST cashback (GST cashback will be partially transferred to the player's Deposit balance) will be taxable at the rate of 30%. (30% TDS will be deducted from the remaining net winnings before transferring the withdrawal amount to the player's bank account.)</p>
                <p>If the player's net winnings are less than the total GST cashback received by the player in the financial year, then a portion of the net winnings-equivalent GST cashback will be transferred to the player's Deposit balance (i.e., only the GST cashback equal to the net winnings will be considered in such a scenario and a portion of that GST cashback amount will be transferred to the player's Deposit balance) and the player will not have to pay any TDS on the withdrawal.</p>
            </div>
        </div>
    );
}

export default GSTDeductions;
