import React, { useEffect } from 'react';
import styles from "../winmoneygameapp/wmga.module.scss"

const FAQ = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className={styles.thirRummy_cont}>
      <h3>FAQ's</h3>

      <div className={styles.content}>
        <h3>Is rummy legal to play in India?</h3>
        <p>Yes, the Supreme Court of India has declared rummy a skill game and ruled that playing a skill game like rummy for real money is a business activity, not betting or gambling. Therefore, it is absolutely legal to play online rummy for both cash and for free in India.</p>
      </div>

      <div className={styles.content}>
        <h3>What is a rummy account?</h3>
        <p>
        A rummy account is an in-app account for all registered players who wish to participate in free or cash rummy games. To participate in cash games and tournaments, you can add cash to your rummy account and join the games/tournaments you like.
        </p>
      </div>

      <div className={styles.content}>
        <h3>Can I use my rummy account to play Ludo?</h3>
        <p>Absolutely yes. Your account is an in-app account for all registered players who wish to participate in free or cash rummy, ludo games. To participate in cash games and tournaments, you can add cash to your rummy account and join the games/tournaments you like both in ludo and rummy.</p>
      </div>

      <div className={styles.content}>
        <h3>How can I play online ludo and rummy on VyKa Games?</h3>
        <p>Download the VyKa Games app for free and begin your ludo and rummy journey on VyKa Games. If you are new to the game, visit our “How To Play” section to learn the game rules and strategies.</p>
      </div>

      <div className={styles.content}>
        <h3>Is it safe to play on VyKa Games?</h3>
        <p>Absolutely! It is 100% safe to play rummy on the VyKa Games platform. VyKa Games is licensed by the government, and the platform has a certified RNG, a No Bots certificate, SSL security (which is also used by banks), and multiple other fool proof features and technologies to make sure that all player accounts and transactions on VyKa Games are 100% safe and secure, and each and every game is completely fair.</p>
      </div>

      <div className={styles.content}>
        <h3>How do I download the cash rummy app?</h3>
        <p>If you are downloading the cash rummy app using an Android phone, here are the steps to follow::</p>
        <ul>
          <li>Go to Google Play Store, type “VyKa Games” in the search bar, and search.</li>
          <li>Tap on the VyKa Games app and tap “Install.”</li>
          <li>Once the app is installed, tap “Open” to begin your rummy journey.</li>
        </ul>
        <p>And, if you are downloading the cash rummy app using an IOS device, here are the steps to follow:</p>
        <ul>
            <li>Visit the App Store, search “VyKa Games” in the search bar, and search.</li>
            <li>Tap “Get” to install the app.</li>
            <li>Once installed, tap “Open” to start playing rummy.</li>
        </ul>
      </div>

      <div className={styles.content}>
        <h3>Benefits of Playing Games of Skill Like Sequence Games</h3>
        <ol>
          <li><span>Non-Addictive:</span> Unlike gambling, games of skill are less addictive
            since they rely on skill and strategy.</li>
          <li><span>Skill Development:</span> Playing sequence games enhances critical
            thinking, problem-solving, and decision-making skills.</li>
          <li><span>Legal:</span>As per Indian law, games of skill are legal and can award cash
            prizes.</li>
          <li><span>Play Limitations:</span>Specific rules and limitations make games engaging
            and challenging, reducing the risk of addictive behaviors.</li>
        </ol>
      </div>

      <div className={styles.content}>
        <p>You can also explore other real-money game variants like Rummy and
          Poker on vykagames.com</p>
      </div>
    </div>
  );
}

export default FAQ;
